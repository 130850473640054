import SleepingLine from './components/charts/SleepingLine';
import Bar from './components/charts/Bar';
import ActionChart from './components/charts/ActionChart';
import FitHeader from './components/FitHeader';
import SleepSummary from './components/charts/SleepSummary';
import HeartChart from './components/charts/HeartChart';
import { NightSleepCard, AvgHeartRate, AvgOxygenSaturation, Steps } from './components/Cards';
import { ApiProvider } from './context/ApiContext';
import React, { useState, useEffect } from 'react';

const App = () => {

  // State to track screen size
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    // Check screen size and update state
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // Adjust breakpoint for small screens
    };

    // Initial check
    checkScreenSize();

    // Add event listener to handle window resizing
    window.addEventListener('resize', checkScreenSize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  return (
    <ApiProvider>
      <div style={{
        backgroundColor: '#f0f0f0', // Light gray background
        color: 'black',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <div className='container' style={{ paddingBottom: '20px' }}>
          <FitHeader />
        </div>

        <div className='container' style={{ paddingBottom: '20px' }}>
          <div className='row'>
            <div className='col-md-3'><NightSleepCard title="Night Sleep" /></div>
            <div className='col-md-3'><AvgHeartRate title="Avg Heart Rate" /></div>
            <div className='col-md-3'><AvgOxygenSaturation title="Oxygen Saturation (Sp02)" /></div>
            <div className='col-md-3'><Steps title="Steps" /></div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <ActionChart />
            </div>

            <div className="col-md-9">
              <div className='row'>
                <div className='col-md-8' style={{
                  width: isSmallScreen ? '100%' : '66%', // 2/3 width on large screens, full on small
                  paddingBottom: isSmallScreen ? '20px' : '0',  // Padding for spacing on small screens
                }}>
                  <SleepingLine />
                </div>
                <div className='col-md-4' style={{
                  width: isSmallScreen ? '100%' : '33%', // 1/3 width on large screens, full on small
                }}>
                  <SleepSummary />
                </div>
              </div>

              {/* Flex container for HeartChart and Bar */}
              <div className='row' style={{
                display: 'flex',
                flexDirection: isSmallScreen ? 'column' : 'row', // Adjust based on screen size
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: isSmallScreen ? '20px' : '0', // Add bottom margin on small screens
              }}>
                <div className='col-8' style={{
                  width: isSmallScreen ? '100%' : '66%', // 2/3 width on large screens, full on small
                }}>
                  <HeartChart />
                </div>
                <div className='col-4' style={{
                  width: isSmallScreen ? '100%' : '33%', // 1/3 width on large screens, full on small
                }}>
                  <Bar />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ApiProvider>
  );
}

export default App;
